.el-scrollbar[data-v-d3a46f9c] {
  height: 100%;
}
.box .el-scrollbar__wrap[data-v-d3a46f9c] {
  overflow: scroll;
}
.margin-t-20[data-v-d3a46f9c] {
  margin-top: 20px;
}
.margin-b-14[data-v-d3a46f9c] {
  margin-bottom: 14px;
}
.dialogBtn[data-v-d3a46f9c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-d3a46f9c] .UserDeptMultipleTree .el-tree {
  height: 60vh !important;
  max-height: 60vh !important;
}
[data-v-d3a46f9c] .userMultipTree .header {
  height: 38px !important;
  padding: 0;
  text-indent: 12px;
}
[data-v-d3a46f9c] .userMultipTree .header-row {
  line-height: 37px !important;
}
[data-v-d3a46f9c] .el-dialog {
  margin-bottom: 0px !important;
}