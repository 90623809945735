.canvasBox[data-v-742ae05c] {
  width: 100%;
  height: 400px;
}
.bottomBtn[data-v-742ae05c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 12px;
}